import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "components/CustomButton";
import { HookFormInput } from "components/FormInputs";
import { requestPngSvgAnalytics } from "store/rext";
import { getPngSvgReportState } from "store/selectors";
import { convertDateToPST, transformCustomIconsCreated } from "./utils";
import DownloadLink from "components/Download/DownLoadLink";
import CustomTable, {
  ISortingCriteria,
} from "components/CustomTableComponent/CustomTable";

interface IFilterValues {
  startDate: string;
  endDate: string;
  userId: string;
}

const columns = [
  {
    key: "userId",
    header: "Customer Id",
    sort: true,
    sortKey: "userId",
  },
  {
    key: "title",
    header: "Icons Created",
    customValue: (data: any) => transformCustomIconsCreated(data),
    sort: true,
    sortKey: "title",
  },
  {
    key: "isDownloaded",
    header: "Icons Downloaded",
    customValue: (data: any) => {
      if (data.isDownloaded) {
        return `Yes (${Object.keys(data.optionsDownloaded || {}).join("|")})`;
      } else {
        return "";
      }
    },
    sort: true,
    sortKey: "isDownloaded",
  },
  {
    key: "appName",
    header: "App Name",
    sort: false,
    sortKey: "appName",
  },
  {
    key: "hasError",
    header: "hasError",
    customValue: (data: any) => (data.hasError ? "YES" : ""),
    sort: false,
    sortKey: "hasError",
  },
  {
    key: "userReaction",
    header: "Feedback",
    customValue: (data: any) => (`
    Reaction: ${data.userReaction} \n
    Feedback: ${data.userFeedback}
    `),
    sort: false,
    sortKey: ""
  },
  {
    key: "createdAt",
    header: "Time Stamp",
    customValue: (data: any) => convertDateToPST(data.createdAt),
    sort: true,
    sortKey: "createdAt",
  },
];

const yesterday = new Date();
const today = new Date();

const yesterdayFormattedDate = yesterday.toISOString().split('T')[0];
const todayFormattedDate = today.toISOString().split('T')[0];

const DEFAULT_VALUE: IFilterValues = {
  startDate: yesterdayFormattedDate,
  endDate: todayFormattedDate,
  userId: ""
};

function PngSvgReports() {
  const dispatch = useDispatch();
  const { data: reportsData, fetching: reportsFetching } =
    useSelector(getPngSvgReportState);
  const { paginationInfo, records } = reportsData;
  const [currentPage, setCurrentPage] = useState<number>(
    paginationInfo?.currentPage || 0
  );
  const [filterValues, setFilterValues] = useState<IFilterValues>({
    startDate: `${DEFAULT_VALUE.startDate}`,
    endDate: `${DEFAULT_VALUE.endDate}`,
    userId: '',
  });
  const [sortingCriteria, setSortingCriteria] = useState<ISortingCriteria>({
    sortBy: "userId",
    sortOrder: "ASC",
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => {
      return reportsData || DEFAULT_VALUE;
    }, [reportsData]),
  });

  const handleOnSubmit = (data: any) => {
    const { startDate, endDate } = data;
    setFilterValues({
      startDate: startDate,
      endDate: endDate,
      userId: data.userId,
    });
    const transformedData = {
      page: currentPage,
      pageSize: 500,
      startDate: `${data.startDate}T00:00:00-08:00`,
      endDate: `${data.endDate}T23:59:59-08:00`,
      userId: data.userId,
      sort: sortingCriteria.sortBy,
      sortOrder: sortingCriteria.sortOrder,
    };
    dispatch(requestPngSvgAnalytics(transformedData));
  };

  const handlePaginationChange = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
    const updatedBody = {
      ...filterValues,
      sort: sortingCriteria.sortBy,
      sortOrder: sortingCriteria.sortOrder,
      pageSize: 500,
      page: activePage - 1,
    };
    dispatch(requestPngSvgAnalytics(updatedBody));
  };

  const handleSortChange = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria);
  };
  
  // useEffect(() => {
  //   if(!filterValues) {
  //   return () => {
  //     reportsData.records = [];
  //     reportsData.paginationInfo = {};
  //   };
  // }
  // }, [reportsData]);

  const handleFetchDataForSorting = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria)
    return dispatch(requestPngSvgAnalytics({
      ...filterValues,
      page: currentPage,
      pageSize: 500,
      sort: criteria.sortBy,
      sortOrder: criteria.sortOrder,
    }));
  }

  useEffect(() => {
    if(!filterValues) {
      return () => {
        reportsData.records = [];
        reportsData.paginationInfo = {};
      };
    }
  }, [reportsData]);

  useEffect(() => {
    dispatch(requestPngSvgAnalytics({
      page: currentPage,
      pageSize: 500,
      startDate: `${DEFAULT_VALUE.startDate}T00:00:00-08:00`,
      endDate: `${DEFAULT_VALUE.endDate}T23:59:59-08:00`,
      userId: '',
      sort: sortingCriteria.sortBy,
      sortOrder: sortingCriteria.sortOrder,
    }));
  }, []); 

  return (
    <Fragment>
      <CustomTable
        serverSideSorting
        sortingCriteria={sortingCriteria}
        tableData={records || []}
        columns={columns}
        hasPagination
        paginationData={paginationInfo}
        handlePaginationChange={handlePaginationChange}
        currentPage={currentPage}
        fetching={reportsFetching || false}
        onSortChange={handleSortChange}
        fetchData={handleFetchDataForSorting}
      >
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="flex flex-row flex-align-center flex-justify-between"
        >
          <HookFormInput
            control={control}
            errors={errors}
            type="date"
            name="startDate"
            id="startDate"
            label="Start Date"
            baseClassName="width-25 margin-l-3"
            defaultValue={DEFAULT_VALUE.startDate} 
            onChange={(event:any) => setValue('startDate', event.target.value)}
          />
          <HookFormInput
            control={control}
            errors={errors}
            type="date"
            name="endDate"
            id="endDate"
            label="End Date"
            baseClassName="width-25 margin-l-3"
            defaultValue={DEFAULT_VALUE.endDate} 
            onChange={(event:any) => setValue('endDate', event.target.value)}
          />
          <HookFormInput
            control={control}
            errors={errors}
            baseClassName="width-25 margin-l-3"
            label="User Id"
            id="userId"
            name="userId"
          />
          <div className="padding-5">
            <CustomButton
              buttonText="Search"
              type="submit"
              primaryButton
              round
            />
          </div>
        </form>
      </CustomTable>
      <div className="position-absolute" style={{ right: 0, bottom: "10px" }}>
        <DownloadLink
          url="/png-svg/v1/analytics/download"
          fileName="png-report.csv"
          body={{
            startDate: `${filterValues?.startDate}T00:00:00-08:00`,
            endDate: `${filterValues?.endDate}T23:59:59-08:00`,
            userId: filterValues?.userId,
            sort: sortingCriteria.sortBy,
            sortOrder: sortingCriteria.sortOrder,
          }}
        />
      </div>
    </Fragment>
  );
}

export default PngSvgReports;
