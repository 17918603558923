import React from "react";
import { HookFormInput, HookFormSelect } from "components/FormInputs";
import CustomButton from "components/CustomButton";
import { useFieldArray } from "react-hook-form";
import LabelComponent from "components/LabelComponent";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { borderTypeOptions, colorOptions } from "../utils";

interface IProps {
    control: any;
    errors: any
    name : string;
    label?:string;
}

export default function CellBorderForm(props: IProps) {
  const { control, errors, name, label } = props;

  const { fields, append, remove } = useFieldArray({
    name: `${name}.chatMeta.pivotCellprops.border`,
    control,
  });

  const handleAppendPoints = () => {
    append(""); 
  };

  const handleRemovePoints = (index: number) => {
    remove(index);
  };

  return (
    <div className="flex flex-row">
     {label && <div className="text-4 text-bold margin-r-4">{label}</div>}
      <CustomButton
        type="button"
        transparent
        primaryButton
        iconProps={{
          name: CUSTOM_SVG_ICON.PlusCircle,
          svgType: SVGType.CUSTOM,
          size: "huge",
          baseclassname: "text-secondary-color",
        }}
        noOutline
        iconPosition={ICON_POSITION.LEFT}
        handleClick={handleAppendPoints}
      />
      <div className="flex flex-column">
        {fields.map((field: any, index: number) => {
          return (
            <div key={field.id} className="flex flex-row">
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={borderTypeOptions}
                name={`${name}.chatMeta.pivotCellprops.border[${index}].type`}
                label={`Border Type ${index + 1}`}
                id={`${name}.chatMeta.pivotCellprops.border[${index}].type`}
                baseClassName="margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={colorOptions}
                name={`${name}.chatMeta.pivotCellprops.border[${index}].color`}
                label={`Border Color ${index + 1}`}
                id={`${name}.chatMeta.pivotCellprops.border[${index}].color`}
                baseClassName="margin-l-3"
              />
              <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.chatMeta.pivotCellprops.border[${index}].pt`}
                label={`Border Width ${index + 1}`}
                id={`${name}.chatMeta.pivotCellprops.border[${index}].pt`}
                baseClassName="margin-l-3"
              />
               <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.chatMeta.pivotCellprops.border[${index}].borderTransparency`}
                label={`Border Transparency ${index + 1}`}
                id={`${name}.chatMeta.pivotCellprops.border[${index}].borderTransparency`}
                baseClassName="margin-l-3"
              />
              <CustomButton
                type="button"
                transparent
                primaryButton
                iconProps={{
                  name: CUSTOM_SVG_ICON.Delete,
                  svgType: SVGType.CUSTOM,
                  size: "huge",
                  baseclassname: "text-danger-color",
                }}
                noOutline
                iconPosition={ICON_POSITION.LEFT}
                handleClick={() => handleRemovePoints(index)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
