import { Fragment, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createLayout, requestLayoutDetail, updateLayouts } from "store/rext/action";
import { getLayoutFormData, getTemplateFormData } from "store/selectors";
import { useForm } from "react-hook-form";
import { HookFormFieldArray, HookFormInput, HookFormTagInput, HookFormTextarea } from "components/FormInputs";
import CustomButton, { ICON_POSITION } from "components/CustomButton/CustomButton";
import { SVGType } from "components/SvgIcon";
import Loading from "components/Loading";

import "./style.scss";
import AliasForm from "./AliasForm";
import KeysFieldArray from "containers/PPTFactory/TemplateTypeForm/keysFieldArray";

interface ILayoutForm {
  name: string;
  alias: string[];
  insertSelectionPrompt: any;
  newSelectionPrompt: any;
  templateId: string;
}

export const DEFAULT_VALUE: ILayoutForm = {
  name: "",
  alias: [],
  insertSelectionPrompt: "",
  newSelectionPrompt: "",
  templateId: "",
};

function LayoutForm() {
  const { slideLayoutId, templateTypeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: layoutDetail, fetching: layoutFetching } = useSelector(getLayoutFormData);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return layoutDetail || DEFAULT_VALUE;
    }, [layoutDetail]),
  });

  useEffect(() => {
    if (slideLayoutId) {
      dispatch(requestLayoutDetail({ slideLayoutId }));
    }
  }, [slideLayoutId, dispatch]);

  const handleOnSubmit = (data: any) => {
    const transformedData = {
      name: data.name,
      alias: data.alias,
      insertSelectionPrompt: {
        system: {
          message: data?.insertSelectionPrompt?.system?.message,
          keys: data?.insertSelectionPrompt?.system?.keys,
        },
        user: {
          message: data?.layouinsertSelectionPrompttPrompt?.user?.message,
          keys: data?.insertSelectionPrompt?.user?.keys,
        },
      },
      newSelectionPrompt: {
        system: {
          message: data?.newSelectionPrompt?.system?.message,
          keys: data?.newSelectionPrompt?.system?.keys,
        },
        user: {
          message: data?.newSelectionPrompt?.user?.message,
          keys: data?.newSelectionPrompt?.user?.keys,
        },
      },
      templateTypeId: layoutDetail.templateTypeId || templateTypeId,
    };

    if (slideLayoutId) {
      dispatch(updateLayouts({ slideLayoutId }, transformedData));
    } else {
      dispatch(createLayout(transformedData));
    }
  };
  useEffect(() => {
    if (slideLayoutId) {
      reset(layoutDetail);
    } else {
      reset(DEFAULT_VALUE);
    }
  }, [reset, slideLayoutId, layoutDetail]);

  return (
    <Fragment>
      <CustomButton
        primaryButton
        type="button"
        handleClick={() => navigate(-1)}
        iconProps={{
          name: "arrow left",
          svgType: SVGType.SEMANTIC,
          size: "large",
          baseclassname: "text-default-color",
        }}
        iconPosition={ICON_POSITION.RIGHT}
        transparent
        noOutline
        baseclassname={"cursor-pointer"}
      />
      {layoutFetching && <Loading />}
      {!layoutFetching && (
        <div className="flex flex-row flex-justify-center width-90 height-80 background-white-shade-1 layout-form-wrapper">
          <form onSubmit={handleSubmit(handleOnSubmit)} className="width-70 margin-3 layout-form-container">
            <h3 className="margin-l-3 padding-t-4">{slideLayoutId ? "Update Layout" : "Create Layout"}</h3>
            <div className="height-70 overflow-auto margin-l-4">
              <HookFormInput name="name" id="name" label="Name" control={control} errors={errors} baseClassName="margin-r-4" />
              <AliasForm control={control} errors={errors} name={"alias"} />
              <label className="text-bold text-5 text-underline text-secondary-color">Insert Selection Prompt</label>
              <div className="text-bold text-5 text-underline text-secondary-color">System</div>
              <HookFormTextarea
                id="insertSelectionPrompt.system.message"
                name="insertSelectionPrompt.system.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
                baseClassName="margin-r-4"
              />
              <KeysFieldArray control={control} errors={errors} name="insertSelectionPrompt.system.keys" label={"keys"} />
              <label className="text-bold text-5 text-underline text-secondary-color">User</label>
              <HookFormTextarea
                id="insertSelectionPrompt.user.message"
                name="insertSelectionPrompt.user.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
                baseClassName="margin-r-4"
              />
              <KeysFieldArray control={control} errors={errors} name="insertSelectionPrompt.user.keys" label={"keys"} />
              <label className="text-bold text-5 text-underline text-secondary-color">New Selection Prompt</label>
              <div className="text-bold text-5 text-underline text-secondary-color">System</div>
              <HookFormTextarea
                id="newSelectionPrompt.system.message"
                name="newSelectionPrompt.system.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
                baseClassName="margin-r-4"
              />
              <KeysFieldArray control={control} errors={errors} name="newSelectionPrompt.system.keys" label={"keys"} />
              <label className="text-bold text-5 text-underline text-secondary-color">User</label>
              <HookFormTextarea
                id="newSelectionPrompt.user.message"
                name="newSelectionPrompt.user.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
                baseClassName="margin-r-4"
              />
              <KeysFieldArray control={control} errors={errors} name="newSelectionPrompt.user.keys" label={"keys"} />
            </div>
            <div className="flex flex-row">
              <CustomButton
                buttonText={slideLayoutId ? "Update" : "Create"}
                type="submit"
                primaryButton
                round
                baseclassname={"cursor-pointer padding-2 margin-l-3"}
              />
            </div>
          </form>
          <div className="width-30 flex flex-column">
            <img src={"/images/formImage.png"} alt="template" className="width-100 height-100" />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default LayoutForm;
