
export function convertDateToPST(createdAt: string) {
const date = new Date(createdAt);
  // Format the time part as "10:46AM"
  const timeString = date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: 'America/Los_Angeles' });
  // Format the date part as "Feb 13 2024"
  const dateString = date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'America/Los_Angeles' });
  // Concatenate the time and date strings
  const formattedDate = `${timeString} ${dateString}`;

  return formattedDate; 
}

  export const transformCustomPPTCreated = (customValue: any) => {
    if (typeof customValue === 'object') {
      return (
        <div className="flex flex-column width-100">
          <div className="flex text-align-center flex-justify-between"><span className="text-bold text-secondary-color">Prompt:</span> {customValue.prompt || ""}</div>
          <div className="flex text-align-center flex-justify-between"><span className="text-bold text-secondary-color">Colors:</span> { Array.isArray(customValue.colors) ? customValue.colors.join(", ") : ""}</div>
          <div className="flex text-align-center flex-justify-between"><span className="text-bold text-secondary-color">Suggestions:</span> { Array.isArray(customValue.questions) ? customValue.questions.join(", ") : ""}</div>
        </div>
      );
    }
    return String(customValue);
  };


  
// export function convertDateToPST(createdAt: string) {
//   const date = new Date(createdAt);
//     // Format the time part as "10:46AM"
//     const timeString = date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: 'America/Los_Angeles' });
//     // Format the date part as "Feb 13 2024"
//     const dateString = date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'America/Los_Angeles' });
//     // Concatenate the time and date strings
//     const formattedDate = `${timeString} ${dateString}`;
  
//     return formattedDate; 
//   }
//   const KeyPoints = ({ children }:any) => {
//     return (
//       <div className="flex width-90 padding-r-2">
//         <span className="">&#x2022;</span>
//         {children}
//       </div>
//     );
//   };
  
//     export const transformCustomPPTCreated = (customValue: any) => {
//       if (typeof customValue === 'object') {
//         return (
//           <div className="flex flex-column width-100">
//             <div className="flex text-align-center flex-justify-between"><span className="text-bold text-secondary-color">Prompt:</span> {customValue.prompt || ""}</div>
//             <div className="flex text-align-center flex-justify-between"><span className="text-bold text-secondary-color">Colors:</span> { Array.isArray(customValue.colors) ? customValue.colors.join(", ") : ""}</div>
//             {/* <div className="flex text-align-center flex-justify-between"><span className="text-bold text-secondary-color">Suggestions:</span> { Array.isArray(customValue.questions) ? customValue.questions.join(", ") : ""}</div> */}
//             <div className="flex text-align-center flex-justify-between">
//     <span className="text-bold text-secondary-color">Suggestions:</span>
//     <div className="flex flex-column padding-l-2">
//       {Array.isArray(customValue.questions) && customValue.questions.map((question:string, index: number) => (
//         <KeyPoints key={index}>{question}</KeyPoints>
//       ))}
//     </div>
//   </div>
//           </div>
//         );
//       }
//       return String(customValue);
//     };
  