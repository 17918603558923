import {
  HookFormCheckbox,
  HookFormInput,
  HookFormSelect,
} from "components/FormInputs";
import TileComponent from "components/TileComponent/TileComponent";
import LabelComponent from "components/LabelComponent";
import StaticImageForm from "./StaticImageForm";
import { colorOptions } from "../utils";

interface Props {
  control: any;
  errors: any;
  name: string;
  watch?: any;
}

let ImageTypeOptions = [
  { label: "Select", value: undefined },
  { label: "STATIC", value: "STATIC" },
  { label: "DYNAMIC", value: "DYNAMIC" },
  { label: "SVG", value: "SVG" }
];

function MetaTypeImageForm(props: Props) {
  const { errors, control, name, watch } = props;
  const imageType = watch(`${name}.chatMeta.imageType`);

  return (
    <TileComponent title="Enter details for Image">
      <LabelComponent label="For ChatMeta" baseClassName={"width-100"}>
        <div className="flex flex-column width-100">
          <div className="flex flex-row width-100">
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-4"
              label="Width"
              id={`${name}.chatMeta.width`}
              name={`${name}.chatMeta.width`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-4"
              label="Height"
              id={`${name}.chatMeta.height`}
              name={`${name}.chatMeta.height`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              baseClassName="width-15 margin-l-3"
              errors={errors}
              label="Image Sequence"
              id={`${name}.chatMeta.imageSequence`}
              name={`${name}.chatMeta.imageSequence`}
            />
            <HookFormSelect
              validation={{}}
              control={control}
              options={ImageTypeOptions}
              baseClassName="width-15 margin-l-3"
              errors={errors}
              label="Image Type"
              id={`${name}.chatMeta.imageType`}
              name={`${name}.chatMeta.imageType`}
            />

            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-15 margin-l-3"
              label="Id"
              id={`${name}.chatMeta.id`}
              name={`${name}.chatMeta.id`}
            />
          </div>
          <div className="flex flex-row width-100">
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewX1"
              id={`${name}.chatMeta.skewX1`}
              name={`${name}.chatMeta.skewX1`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewX2"
              id={`${name}.chatMeta.skewX2`}
              name={`${name}.chatMeta.skewX2`}
            />

            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewX3"
              id={`${name}.chatMeta.skewX3`}
              name={`${name}.chatMeta.skewX3`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewX4"
              id={`${name}.chatMeta.skewX4`}
              name={`${name}.chatMeta.skewX4`}
            />
          </div>
          <div className="flex flex-row width-100">
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewY1"
              id={`${name}.chatMeta.skewY1`}
              name={`${name}.chatMeta.skewY1`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewY2"
              id={`${name}.chatMeta.skewY2`}
              name={`${name}.chatMeta.skewY2`}
            />

            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewY3"
              id={`${name}.chatMeta.skewY3`}
              name={`${name}.chatMeta.skewY3`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="SkewY4"
              id={`${name}.chatMeta.skewY4`}
              name={`${name}.chatMeta.skewY4`}
            />
          </div>

          <div className="flex flex-row width-100">
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="TopLeft Radius"
              id={`${name}.chatMeta.roundCorners.topLeft`}
              name={`${name}.chatMeta.roundCorners.topLeft`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="TopRight Radius"
              id={`${name}.chatMeta.roundCorners.topRight`}
              name={`${name}.chatMeta.roundCorners.topRight`}
            />

            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="BottomLeft radius"
              id={`${name}.chatMeta.roundCorners.bottomLeft`}
              name={`${name}.chatMeta.roundCorners.bottomLeft`}
            />
            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-20 margin-r-3"
              label="BottomRight Radius"
              id={`${name}.chatMeta.roundCorners.bottomRight`}
              name={`${name}.chatMeta.roundCorners.bottomRight`}
            />
          </div>
          
          <div className="flex flex-row width-100">
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.chatMeta.blitColor`}
              label={"Blit Color"}
              id={`${name}.chatMeta.blitColor`}
              baseClassName="width-30 margin-r-3"
            />

            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-30"
              label="Blit Color Trasnparnecy"
              id={`${name}.chatMeta.blitColorTrasnparnecy`}
              name={`${name}.chatMeta.blitColorTrasnparnecy`}
            />

            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.chatMeta.fillColor`}
              label={"Fill Color"}
              id={`${name}.chatMeta.fillColor`}
              baseClassName="width-30 margin-r-3"
            />

            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.chatMeta.staticColor`}
              label="Static Color"
              id={`${name}.chatMeta.staticColor`}
              baseClassName="width-15 margin-l-3"
            />
          </div>
        </div>
      </LabelComponent>
      <LabelComponent label="Image Directory">
        <HookFormInput
          validation={{}}
          control={control}
          errors={errors}
          baseClassName="width-30 margin-l-3"
          label="Slide Image Directory"
          id={`${name}.chatMeta.slideImageDir`}
          name={`${name}.chatMeta.slideImageDir`}
        />
      </LabelComponent>
      <LabelComponent label="For Image" baseClassName={"width-100"}>
        <HookFormInput
          validation={{}}
          control={control}
          errors={errors}
          baseClassName="width-20 margin-r-4"
          label="X Position."
          id={`${name}.optionsMeta.x`}
          name={`${name}.optionsMeta.x`}
        />
        <HookFormInput
          validation={{}}
          control={control}
          errors={errors}
          baseClassName="width-20 margin-r-4"
          label="Y Position"
          id={`${name}.optionsMeta.y`}
          name={`${name}.optionsMeta.y`}
        />
        <HookFormInput
          validation={{}}
          control={control}
          errors={errors}
          baseClassName="width-20 margin-r-4"
          label="Rotate"
          id={`${name}.optionsMeta.rotate`}
          name={`${name}.optionsMeta.rotate`}
        />
        <HookFormCheckbox
          validation={{}}
          control={control}
          errors={errors}
          baseClassName="width-20"
          label={"Rounding"}
          id={`${name}.optionsMeta.rounding`}
          name={`${name}.optionsMeta.rounding`}
        />
      </LabelComponent>
      {(imageType === "STATIC" || imageType === "SVG") && (
        <StaticImageForm control={control} name={name} errors={errors} />
      )}
    </TileComponent>
  );
}

export default MetaTypeImageForm;
