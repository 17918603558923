export default () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 572.098 572.097"
    >
      <g>
        <g>
          <path
          fill="currentColor"
            d="M286.049,128.572c-107.663,0-209.738,52.155-273.038,139.524L0,286.052l13.011,17.957
       c63.299,87.362,165.375,139.518,273.038,139.518s209.738-52.155,273.038-139.524l13.011-17.956l-13.011-17.956
       C495.787,180.733,393.712,128.572,286.049,128.572z M370.125,244.154c0,17.02-13.794,30.82-30.82,30.82
       c-17.025,0-30.82-13.801-30.82-30.82c0-17.02,13.801-30.82,30.82-30.82C356.325,213.334,370.125,227.134,370.125,244.154z
        M286.049,382.326c-80.466,0-157.125-35.612-209.292-96.274c29.192-33.941,66.053-60.025,106.953-76.433
       c-6.279,14.002-9.859,29.486-9.859,45.833c0,61.965,50.233,112.198,112.198,112.198s112.198-50.233,112.198-112.198
       c0-16.347-3.574-31.824-9.859-45.833c40.899,16.408,77.761,42.491,106.953,76.433
       C443.174,346.713,366.515,382.326,286.049,382.326z"
          />
        </g>
      </g>
    </svg>
  );
};
