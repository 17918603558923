import CustomTable, { IColumns, ISortingCriteria } from 'components/CustomTableComponent/CustomTable';
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { requestPPTHistory } from 'store/rext';
import { getPPTHistoryState } from 'store/selectors';
import { convertDateToPST } from './utils';
import DownloadLink from 'components/Download/DownLoadLink';

const columns: IColumns[] = [
  {
    key: "pptRef",
    header: "Id",
    sort: true,
    sortKey: "pptRef",
  },
  {
    key: "event",
    header: "Event Type",
    sort: true,
    sortKey: "event",
  },
  {
    key: "createdAt",
    header: "Created At",
    customValue: (data: any) => convertDateToPST(data.createdAt),
    sort: true,
    sortKey: "createdAt",
  },
  {
    key: "updatedAt",
    header: "Updated At",
    customValue: (data: any) => convertDateToPST(data.createdAt),
    sort: true,
    sortKey: "updatedAt",
  },
];

function PPTHistory() {
  const {id} = useParams();
  const dispatch = useDispatch();
  const { data: historyData, fetching: historyFetching } = useSelector(getPPTHistoryState);
  const [sortingCriteria, setSortingCriteria] = useState<ISortingCriteria>({
    sortBy: "event",
    sortOrder: "ASC",
  });

  const handleSortChange = (criteria: ISortingCriteria) => {
    setSortingCriteria(criteria);
  };
  
  useEffect(() => {
    return () => {
      historyData.records = [];
      historyData.paginationInfo = {};
    };
  }, [historyData]);

  useEffect(()=> {
    dispatch(requestPPTHistory({execution_id : id}))
  }, [])

  return (
    <Fragment>
      <div className='flex flex-justify-end margin-t-3'>
        <DownloadLink
          url={`/ppt/v1/info/${id}/sentimental`}
          isUrlDownload
          buttonText='Download Sentimental'
        />
        <DownloadLink
          url={`/ppt/v1/info/${id}/info`}
          isUrlDownload
          buttonText='Download Info'
        />
        <DownloadLink
          url={`/ppt/v1/pptdownload/${id}`}
          urlParams={{ execution_id: id }}
          fileName={`${id}.pptx`}
          isUrlDownload
          buttonText='Download PPT'
        />
    </div>
    <CustomTable
    tableData={historyData.history || []}
    columns={columns}
    hasPagination={false}
    fetching={historyFetching || false} 
    sortTable
    sortingCriteria={sortingCriteria}
    onSortChange={handleSortChange}
    />
    </Fragment>
    
  )
}

export default PPTHistory