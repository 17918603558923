import { useFieldArray } from "react-hook-form";
import { HookFormInput } from "components/FormInputs";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";

interface IFields {
  name: string;
  label: string;
  validation?: any;
}

interface IFieldArrayFormProps {
  baseName: string;
  control: any;
  errors: any;
  fields: Array<IFields>;
  label: string;
}

export function HookFormFieldArray({
  baseName,
  control,
  errors,
  fields,
  label,
}: IFieldArrayFormProps) {
  const {
    fields: fieldArray,
    append,
    remove,
  } = useFieldArray({
    name: `${baseName}`,
    control,
  });
  
  const firstLetterCapitalized = label.charAt(0).toUpperCase();
  
  const handleAppend = () => {
    append({});
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <div className="margin-l-2 flex flex-column">
      <div className="flex flex-row padding-2 flex-align-center">
        <label className="text-4 text-bold">{label}</label>
        <CustomButton
          type="button"
          transparent
          primaryButton
          iconProps={{
            name: CUSTOM_SVG_ICON.PlusCircle,
            svgType: SVGType.CUSTOM,
            size: "huge",
            baseclassname: "text-secondary-color",
          }}
          noOutline
          iconPosition={ICON_POSITION.LEFT}
          handleClick={handleAppend}
        />
      </div>
      {fieldArray.map((field: any, index: number) => (
        <div key={field.id} className="flex flex-row">
          <div className="flex flex-row flex-justify-center flex-align-center background-secondary-color width-5 margin-t-2 margin-b-4 text-5 text-primary-color">
            {firstLetterCapitalized}
            {index + 1}
          </div>
          {fields.map((configField) => {
            return (
              <HookFormInput
                key={configField.name}
                id={`${baseName}[${index}].${configField.name}`}
                name={`${baseName}[${index}].${configField.name}`}
                label={configField.label}
                errors={errors}
                validation={configField.validation}
                control={control}
                baseClassName="width-30 margin-l-3"
              />
            );
          })}

          {fieldArray.length !== 1 && (
            <CustomButton
              type="button"
              transparent
              primaryButton
              iconProps={{
                name: CUSTOM_SVG_ICON.Delete,
                svgType: SVGType.CUSTOM,
                size: "huge",
                baseclassname: "text-danger-color",
              }}
              noOutline
              iconPosition={ICON_POSITION.LEFT}
              handleClick={() => handleRemove(index)}
            />
          )}
        </div>
      ))}
    </div>
  );
}