import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable, { IColumns } from "components/CustomTableComponent/CustomTable";
import { requestDownloadLayoutCount } from "store/rext";
import { getDownloadLayoutCountState } from "store/selectors";

import FilterContainer, { DEFAULT_VALUE } from "./Filter";
import classNames from "classnames";

const TemplateTypeOptions = [
  { label: "GENERIC", value: "GENERIC" },
  { label: "PITCH DECK", value: "PITCH_DECK" },
];

const layoutDownloadColumns: IColumns[] = [
  {
    key: "layoutName",
    header: "Name",
  },
  {
    key: "count",
    header: "Usage",
  },
];


function DownloadLayoutContainer() {
  const dispatch = useDispatch();
  const { data: downloadLayoutData, fetching: downloadLayoutFetching } = useSelector(
    getDownloadLayoutCountState
  );

  const [selectedTemplateType, setSelectedTemplateType] = useState(TemplateTypeOptions[0].value);

  const records = useMemo(() => {
    return downloadLayoutData[selectedTemplateType] || [];
  }, [selectedTemplateType, downloadLayoutData])


  useEffect(() => {
    dispatch(
      requestDownloadLayoutCount({
        startDate: `${DEFAULT_VALUE.startDate}T00:00:00-08:00`,
        endDate: `${DEFAULT_VALUE.endDate}T23:59:59-08:00`,
      })
    );
  }, [dispatch]);

  const handleOnSubmit = (filterData: any) => {
    dispatch(requestDownloadLayoutCount(filterData));
  };

  return (
    <Fragment>
      <FilterContainer handleFilterChange={handleOnSubmit} />

      <div className={classNames(["flex flex-column custom-input width-30 margin-l-3"])}>
        <div className="text-4 text-bold">
          Template Type
        </div>
        <select
          value={selectedTemplateType || ""}
          onChange={(e) => {
            setSelectedTemplateType(e.target.value);
          }}
        >
          {TemplateTypeOptions?.map(({ label, value }: any, index: number) => (
            <option value={value} key={`Option-${index}`}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <CustomTable
        tableData={records}
        columns={layoutDownloadColumns}
        hasPagination={false}
        fetching={downloadLayoutFetching || false}
        sortTable={false}
      />
    </Fragment>
  );
}

export default DownloadLayoutContainer;
