import { HookFormInput, HookFormSelect } from "components/FormInputs";
import TileComponent from "components/TileComponent/TileComponent";
import LabelComponent from "components/LabelComponent";
import { gradientDirectionOptions, gradientShapeOptions } from "../utils";
import GradientColorForm from "./GradientColorForm";

interface Props {
  control: any;
  errors: any;
  name: string;
  watch?: any;
}

function MetaTypeGradientForm(props: Props) {
  const { errors, control, name, watch } = props;
  const gradientShape = watch(`${name}.optionsMeta.gradientShape`);

  return (
    <TileComponent title="Enter details for Gradient">
      <LabelComponent label="Options for Gradient" baseClassName={"width-100"}>
        <div className="flex flex-column width-100">
          <div className="flex flex-row width-100 ">
            <HookFormSelect
              validation={{}}
              control={control}
              options={gradientShapeOptions}
              baseClassName="width-15 margin-l-3"
              errors={errors}
              label="Gradient Shape"
              id={`${name}.optionsMeta.gradientShape`}
              name={`${name}.optionsMeta.gradientShape`}
            />
            <HookFormSelect
              validation={{}}
              control={control}
              options={gradientDirectionOptions}
              baseClassName="width-20 margin-l-3"
              errors={errors}
              label="Gradient Direction"
              id={`${name}.optionsMeta.gradientDirection`}
              name={`${name}.optionsMeta.gradientDirection`}
            />
          </div>
          <GradientColorForm
            control={control}
            errors={errors}
            name={name}
            label="Gradient Color Options"
          />
          <div className="flex flex-row width-100 margin-t-3">
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.x`}
              label="X Position"
              id={`${name}.optionsMeta.x`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.y`}
              label="Y Position"
              id={`${name}.optionsMeta.y`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              validation={{}}
              errors={errors}
              name={`${name}.optionsMeta.w`}
              label="Width"
              id={`${name}.optionsMeta.w`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.h`}
              label="Height"
              id={`${name}.optionsMeta.h`}
              baseClassName="width-15 margin-l-3"
            />
            {/* {gradientShape === "Rectangle" && (
              <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.optionsMeta.rectRadius`}
                label="RectRadius"
                id={`${name}.optionsMeta.rectRadius`}
                baseClassName="width-10 margin-l-3"
              />
            )} */}
          </div>
        </div>
      </LabelComponent>
    </TileComponent>
  );
}

export default MetaTypeGradientForm;
