import { Fragment } from "react";
import { Tab } from "semantic-ui-react";
import PPTAnalytics from "./DownloadPPTAnalytics";
import LayoutAnalytics from "./DownloadLayoutAnalytics"


const panes = [
  {
    menuItem: {
      key: "pptAnalytics",
      icon: "calendar alternate outline",
      content: "PPT Analytics",
    },
    render: () => (
      <PPTAnalytics/>
    ),
  },
  {
    menuItem: { key: "layoutAnalytics", icon: "download", content: "Layout Analytics" },
    render: () => (
      <Fragment>
        <LayoutAnalytics/>
      </Fragment>
    ),
  },
];

function AnalyticsContainer() {
  return <Tab menu={{ pointing: true }} panes={panes} />;
}

export default AnalyticsContainer;
