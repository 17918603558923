import { useNavigate } from "react-router-dom";
import MenuCard from "components/MenuCardComponent/MenuCard";
import { cardDetails } from "utils/CardDetails";


function Dashboard() {
  const navigate = useNavigate();
  const renderCards = ({title , desc , svg , link}:any , index:number) => {
    const handleOnclick = () => {
    navigate(link)
    }
    return <MenuCard key={`Card-${index}`} title={title} desc={desc} svg={svg} onClick={handleOnclick}/>
  }

  return (
    <div className='flex flex-row flex-justify-center flex-align-center flex-wrap height-100 dashboard-wrapper'>
      {cardDetails.map(renderCards)}
    </div>
  )
}

export default Dashboard;