import React from "react";
import classNames from "classnames";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

interface IHeader {
    handleBackClick: () => void;
    title: string;
    buttonText:string;
    handleSecondaryButtonClick: any;
}

function HeaderComponent(props: IHeader) {
  const { title , handleBackClick, buttonText, handleSecondaryButtonClick } = props;

  return (
    <div className="flex flex-justify-between flex-align-center padding-4">
    <CustomButton
      primaryButton
      type="button"
      handleClick={handleBackClick}
      iconProps={{
        name: "arrow left",
        svgType: SVGType.SEMANTIC,
        size: "large",
        baseclassname: "text-default-color",
      }}
      iconPosition={ICON_POSITION.RIGHT}
      transparent
      noOutline
      baseclassname={"cursor-pointer"}
    />
    <div className="text-7 text-bold text-secondary-color text-align-center padding-t-2">{title}</div>
    <CustomButton
      buttonText={buttonText}
      gradientButton
      handleClick={handleSecondaryButtonClick}
      baseclassname={"margin-r-4 padding-2 cursor-pointer"}
    />
  </div>
  );
}
export default HeaderComponent;
