import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import CustomButton from "components/CustomButton";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { HookFormFieldArray, HookFormInput } from "components/FormInputs";
import { HookFormTextarea } from "components/FormInputs/HookFormTextarea";
import { SVGType } from "components/SvgIcon";
import { createTemplateType, requestTemplateTypeDetail, updateTemplateType } from "store/rext";
import { getTemplateTypeFormData } from "store/selectors";
import { hookformRequired } from "utils/FormValidations";
import Loading from "components/Loading";
import { SuggestionPromptsForm } from "./SuggestionPromptsForm";
import KeysFieldArray from "./keysFieldArray";

import "./style.scss";
const requiredValidation = {
  ...hookformRequired("Required"),
};
interface Ilayout {
  title: string;
  layoutType: string;
}

interface IQuestions {
  question: string;
  systemMessage: string;
  keyId: string;
}

interface ITemplateType {
  name: string;
  defaultLayout: string;
  layouts: Ilayout[];
  predefineSystemMessage: string;
  questions: IQuestions[];
}

export const DEFAULT_VALUE: ITemplateType = {
  name: "",
  defaultLayout: "",
  layouts: [
    // {
    //   title: "",
    //   layoutType: "",
    // },
  ],
  predefineSystemMessage: "",
  questions: [
    // {
    //   question: "",
    //   systemMessage: "",
    // },
  ],
};

export default function TemplateTypeContainer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: templatetypeDetail, fetching: templateTypeFetching, error } = useSelector(getTemplateTypeFormData);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      return templatetypeDetail || DEFAULT_VALUE;
    }, [templatetypeDetail]),
  });

  const handleOnSubmit = (data: any) => {
    const transformedData = {
      name: data.name,
      defaultLayout: data.defaultLayout,
      layouts: data?.layouts?.map((item: any) => ({
        title: item.title,
        layoutType: item.layoutType,
      })),
      predefineSystemMessage: data.predefineSystemMessage,
      suggestionPrompts: data.suggestionPrompts,
      // questions: data?.questions?.map((item: any) => ({
      //   question: item.question,
      //   systemMessage: item.systemMessage,
      //   keyId: item.keyId,
      //   placeholder: item.placeholder,
      //   caption: item.caption,
      //   answerKeyName: item.answerKeyName,
      // })),
      slidePrompt: {
        message: data?.slidePrompt?.message,
        keys: data?.slidePrompt?.keys,
      },
      topicPrompt: {
        multiple: {
          system: {
            message: data?.topicPrompt?.multiple?.system?.message,
            keys: data?.topicPrompt?.multiple?.system?.keys,
          },
          user: {
            message: data?.topicPrompt?.multiple?.user?.message,
            keys: data?.topicPrompt?.multiple?.user?.keys,
          },
        },
        single: {
          system: {
            message: data?.topicPrompt?.single?.system?.message,
            keys: data?.topicPrompt?.single?.system?.keys,
          },
          user: {
            message: data?.topicPrompt?.single?.user?.message,
            keys: data?.topicPrompt?.single?.user?.keys,
          },
        },
        // system: {
        //   message: data?.topicPrompt?.system?.message,
        //   keys: data?.topicPrompt?.system?.keys,
        // },
        // user: {
        //   message: data?.topicPrompt?.user?.message,
        //   keys: data?.topicPrompt?.user?.keys,
        // },
      },
      layoutPrompt: {
        multiple: {
          system: {
            message: data?.layoutPrompt?.multiple?.system?.message,
            keys: data?.layoutPrompt?.multiple?.system?.keys,
          },
          user: {
            message: data?.layoutPrompt?.multiple?.user?.message,
            keys: data?.layoutPrompt?.multiple?.user?.keys,
          },
        },
        single: {
          system: {
            message: data?.layoutPrompt?.single?.system?.message,
            keys: data?.layoutPrompt?.single?.system?.keys,
          },
          user: {
            message: data?.layoutPrompt?.single?.user?.message,
            keys: data?.layoutPrompt?.single?.user?.keys,
          },
        },
        // system: {
        //   message: data?.layoutPrompt?.system?.message,
        //   keys: data?.layoutPrompt?.system?.keys,
        // },
        // user: {
        //   message: data?.layoutPrompt?.user?.message,
        //   keys: data?.layoutPrompt?.user?.keys,
        // },
      },
    };
    if (id) {
       dispatch(updateTemplateType({ id: id }, transformedData));
      // toast.success("Template Type Updated successfully!")
    } else {
      dispatch(createTemplateType(transformedData));
      // toast.success("Template Type Created successfully!")
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(requestTemplateTypeDetail({ id: id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      reset(templatetypeDetail);
    } else {
      reset(DEFAULT_VALUE);
    }
  }, [reset, id, templatetypeDetail]);

  return (
    <div className="height-100">
      <CustomButton
        primaryButton
        type="button"
        handleClick={() => navigate(-1)}
        iconProps={{
          name: "arrow left",
          svgType: SVGType.SEMANTIC,
          size: "large",
          baseclassname: "text-default-color",
        }}
        iconPosition={ICON_POSITION.RIGHT}
        transparent
        noOutline
        baseclassname={"cursor-pointer"}
      />
      {templateTypeFetching && <Loading />}
      {!templateTypeFetching && (
        <div className="flex flex-row flex-justify-center height-90 flex-align-center">
          <form onSubmit={handleSubmit(handleOnSubmit)} className=" flex flex-column width-80  height-90 templatetype-container">
            <div className="text-6 text-bold margin-4">{id ? "Update Template Type" : "Create Template Type"}</div>
            <div className="padding-l-4 padding-r-4 height-75 overflow-auto">
              <HookFormInput name="name" id="name" label="Template Name" control={control} errors={errors} />
              <HookFormInput name="defaultLayout" id="defaultLayout" label="DefaultLayout Name" control={control} errors={errors} />
              <HookFormTextarea id="predefineSystemMessage" name="predefineSystemMessage" label="Predefined System message" control={control} errors={errors} />
              <HookFormFieldArray
                label="Layouts"
                control={control}
                errors={errors}
                baseName="layouts"
                fields={[
                  { name: "title", label: "TITLE" },
                  { name: "layoutType", label: "LAYOUT TYPE" },
                ]}
              />
              {/* 
              <HookFormFieldArray
                label="Questions"
                control={control}
                errors={errors}
                baseName="questions"
                fields={[
                  { name: "question", label: "QUESTION" },
                  { name: "systemMessage", label: "SYSTEM MESSAGE" },
                  { name: "keyId", label: "KEY ID" },
                  { name: "placeholder", label: "PLACEHOLDER" },
                  { name: "caption", label: "CAPTION" },
                  { name: "answerKeyName", label: "ANSWER KEY NAME" },
                ]}
              /> */}

              <SuggestionPromptsForm label="Suggestion Prompts" name="suggestionPrompts" control={control} errors={errors} />
              <label className="text-bold text-5 text-underline text-secondary-color">Slide Prompt</label>
              <HookFormTextarea id="slidePrompt.message" name="slidePrompt.message" label="Message" control={control} errors={errors} rows={4} />
              <KeysFieldArray control={control} errors={errors} name="slidePrompt.keys" label={"keys"} />

              <label className="text-bold text-5 text-underline text-secondary-color">Topic Prompt</label>
              <div className="text-bold text-5 text-underline text-secondary-color">(Multiple)System</div>
              <HookFormTextarea
                id="topicPrompt.multiple.system.message"
                name="topicPrompt.multiple.system.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />

              <KeysFieldArray control={control} errors={errors} name="topicPrompt.multiple.system.keys" label={"keys"} />

              <label className="text-bold text-5 text-underline text-secondary-color">(Multiple)User</label>
              <HookFormTextarea
                id="topicPrompt.multiple.user.message"
                name="topicPrompt.multiple.user.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />
              <KeysFieldArray control={control} errors={errors} name="topicPrompt.multiple.user.keys" label={"keys"} />
              <div className="text-bold text-5 text-underline text-secondary-color">(Single)System</div>
              <HookFormTextarea
                id="topicPrompt.single.system.message"
                name="topicPrompt.single.system.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />
              <KeysFieldArray control={control} errors={errors} name="topicPrompt.single.system.keys" label={"keys"} />

              <label className="text-bold text-5 text-underline text-secondary-color">(Single)User</label>
              <HookFormTextarea
                id="topicPrompt.single.user.message"
                name="topicPrompt.single.user.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />
              <KeysFieldArray control={control} errors={errors} name="topicPrompt.single.user.keys" label={"keys"} />

              <label className="text-bold text-5 text-underline text-secondary-color">Layout Prompt</label>
              <div className="text-bold text-5 text-underline text-secondary-color">(Multiple)System</div>
              <HookFormTextarea
                id="layoutPrompt.multiple.system.message"
                name="layoutPrompt.multiple.system.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />

              <KeysFieldArray control={control} errors={errors} name="layoutPrompt.multiple.system.keys" label={"keys"} />

              <label className="text-bold text-5 text-underline text-secondary-color">(Multiple)User</label>
              <HookFormTextarea
                id="layoutPrompt.multiple.user.message"
                name="layoutPrompt.multiple.user.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />
              <KeysFieldArray control={control} errors={errors} name="layoutPrompt.multiple.user.keys" label={"keys"} />
              <div className="text-bold text-5 text-underline text-secondary-color">(Single)System</div>
              <HookFormTextarea
                id="layoutPrompt.single.system.message"
                name="layoutPrompt.single.system.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />
              <KeysFieldArray control={control} errors={errors} name="layoutPrompt.single.system.keys" label={"keys"} />

              <label className="text-bold text-5 text-underline text-secondary-color">(Single)User</label>
              <HookFormTextarea
                id="layoutPrompt.single.user.message"
                name="layoutPrompt.single.user.message"
                label="Message"
                control={control}
                errors={errors}
                rows={4}
              />
              <KeysFieldArray control={control} errors={errors} name="layoutPrompt.single.user.keys" label={"keys"} />
            </div>
            <div className="flex flex-row flex-justify-center flex-align-center padding-2">
              <CustomButton buttonText={id ? "Update" : "Create"} type="submit" primaryButton round baseclassname={"cursor-pointer"} />
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
