import { HookFormInput } from "components/FormInputs";
import { HookFormSelect } from "components/FormInputs/HookSelect";
import {
  ChartType,
  ChartTypeOptions,
  DEFAULT_THEME_COLOR,
  alignOptions,
  axisLineStyleOptions,
  colorOptions,
  dataSymbolOptions,
  fontFaceOptions,
  legendPositionOptions,
  lineDataSymbols,
} from "../utils";
import TileComponent from "components/TileComponent/TileComponent";
import LabelComponent from "components/LabelComponent/LabelComponent";
import { HookFormCheckbox } from "components/FormInputs/HookFormCheckBox";
import ChartTickForm from "./ChartTickForm";
import { useFieldArray } from "react-hook-form";
import CustomButton from "components/CustomButton";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import ChartColorForm from "./ChartColorForm";

interface Props {
  control: any;
  errors: any;
  name: string;
  defaultValue?: any;
  watch?: any;
}

function MetaTypeChartForm(props: Props) {
  const { errors, control, name, watch } = props;
  const title: boolean = watch(`${name}.optionsMeta.showTitle`);
  const legends: boolean = watch(`${name}.optionsMeta.showLegend`);

  // const { fields, append, remove } = useFieldArray({
  //   name: `${name}.optionsMeta.chartColors`,
  //   control,
  // });

  return (
    <TileComponent title="Enter Details for Chart">
      <LabelComponent label="ChatMeta">
        <HookFormSelect
          control={control}
          errors={errors}
          validation={{}}
          options={ChartTypeOptions}
          name={`${name}.chatMeta.chartType`}
          label="Chart Type"
          id={`${name}.chatMeta.chartType`}
          baseClassName="width-10 margin-l-3"
        />
        <HookFormInput
          control={control}
          errors={errors}
          validation={{}}
          name={`${name}.chatMeta.id`}
          label="Id"
          id={`${name}.chatMeta.id`}
          baseClassName="width-10 margin-l-3"
        />

        <HookFormInput
          control={control}
          errors={errors}
          validation={{}}
          name={`${name}.chatMeta.noOfSeries`}
          label="No. of Series"
          id={`${name}.chatMeta.noOfSeries`}
          baseClassName="width-10 margin-l-3"
        />
        <HookFormInput
          control={control}
          errors={errors}
          validation={{}}
          name={`${name}.chatMeta.noOfPoints`}
          label="No. of Points"
          id={`${name}.chatMeta.noOfPoints`}
          baseClassName="width-10 margin-l-3"
        />

        <HookFormCheckbox
          validation={{}}
          control={control}
          errors={errors}
          baseClassName="width-10 margin-l-3"
          label={"X Axis Title"}
          id={`${name}.chatMeta.showXAxisTitle`}
          name={`${name}.chatMeta.showXAxisTitle`}
        />

        <HookFormCheckbox
          validation={{}}
          control={control}
          errors={errors}
          baseClassName="width-10 margin-l-3"
          label={"Y Axis Title"}
          id={`${name}.chatMeta.showYAxisTitle`}
          name={`${name}.chatMeta.showYAxisTitle`}
        />
      </LabelComponent>
      <LabelComponent label="Options For Chart">
        <div className="flex flex-column width-100">
          <div className="flex flex-row width-100">
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.x`}
              label="X Position"
              id={`${name}.optionsMeta.x`}
              baseClassName="width-10 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.y`}
              label="Y Position"
              id={`${name}.optionsMeta.y`}
              baseClassName="width-10 margin-l-3"
            />
            <HookFormInput
              control={control}
              validation={{}}
              errors={errors}
              name={`${name}.optionsMeta.w`}
              label="Width"
              id={`${name}.optionsMeta.w`}
              baseClassName="width-10 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.h`}
              label="Height"
              id={`${name}.optionsMeta.h`}
              baseClassName="width-10 margin-l-3"
            />
            <HookFormCheckbox
              validation={{}}
              control={control}
              baseClassName="width-10 margin-l-3"
              errors={errors}
              label={"Show Title"}
              name={`${name}.optionsMeta.showTitle`}
              id={`${name}.optionsMeta.showTitle`}
            />
            <HookFormCheckbox
              validation={{}}
              control={control}
              baseClassName="width-10 margin-l-3"
              errors={errors}
              label={"Show Legend"}
              name={`${name}.optionsMeta.showLegend`}
              id={`${name}.optionsMeta.showLegend`}
            />
            <HookFormCheckbox
              validation={{}}
              control={control}
              baseClassName="width-10 margin-l-3"
              errors={errors}
              label={"Show Value"}
              name={`${name}.optionsMeta.showValue`}
              id={`${name}.optionsMeta.showValue`}
            />
            <HookFormCheckbox
              validation={{}}
              control={control}
              baseClassName="width-10 margin-l-3"
              errors={errors}
              label={"Show Percent"}
              name={`${name}.optionsMeta.showPercent`}
              id={`${name}.optionsMeta.showPercent`}
            />
            <HookFormCheckbox
              validation={{}}
              control={control}
              baseClassName="width-10 margin-l-3"
              errors={errors}
              label={"Show Label"}
              name={`${name}.optionsMeta.showLabel`}
              id={`${name}.optionsMeta.showLabel`}
            />
          </div>
          <div className="flex flex-row width-100">
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={dataSymbolOptions}
              name={`${name}.optionsMeta.lineDataSymbol`}
              label={"Line Data Symbol"}
              id={`${name}.optionsMeta.lineDataSymbol`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.lineDataSymbolSize`}
              label="Line Data Symbol Size"
              id={`${name}.optionsMeta.lineDataSymbolSize`}
              baseClassName="width-15 margin-l-3"
            />

            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              label="Line Size"
              name={`${name}.optionsMeta.lineSize`}
              id={`${name}.optionsMeta.lineSize`}
              baseClassName="width-15 margin-l-4"
            />

            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.barDir`}
              label="Bar Direction"
              id={`${name}.optionsMeta.barDir`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.barOverlapPct`}
              label="Gap Width"
              id={`${name}.optionsMeta.barOverlapPct`}
              baseClassName="width-15 margin-l-3"
            />

            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.barGapWidthPct`}
              label="Bar Width"
              id={`${name}.optionsMeta.barGapWidthPct`}
              baseClassName="width-15 margin-l-3"
            />
          </div>
          <LabelComponent label="Cat Axis Options" baseClassName={"width-100"}>
              <HookFormCheckbox
                validation={{}}
                control={control}
                baseClassName="width-10 margin-l-3"
                errors={errors}
                label={"CAT Axis Title"}
                name={`${name}.optionsMeta.showCatAxisTitle`}
                id={`${name}.optionsMeta.showCatAxisTitle`}
              />
              <HookFormCheckbox
                validation={{}}
                control={control}
                baseClassName="width-10 margin-l-3"
                errors={errors}
                label={"CAT Axis"}
                name={`${name}.optionsMeta.catAxisHidden`}
                id={`${name}.optionsMeta.catAxisHidden`}
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={axisLineStyleOptions}
                name={`${name}.optionsMeta.catGridLine.style`}
                label={"CAT GridLine"}
                id={`${name}.optionsMeta.catGridLine.style`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={colorOptions}
                name={`${name}.optionsMeta.catAxisLabelColor`}
                label="Label Color "
                id={`${name}.optionsMeta.catAxisLabelColor`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.optionsMeta.catAxisLabelFontSize`}
                label="Label Fontsize "
                id={`${name}.optionsMeta.catAxisLabelFontSize`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={fontFaceOptions}
                name={`${name}.optionsMeta.catAxisLabelFontFace`}
                label="Label Fontface"
                id={`${name}.optionsMeta.catAxisLabelFontFace`}
                baseClassName="width-10 margin-l-3"
              />

              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={colorOptions}
                name={`${name}.optionsMeta.catAxisTitleColor`}
                label="Title Color "
                id={`${name}.optionsMeta.catAxisTitleColor`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.optionsMeta.catAxisTitleFontSize`}
                label="Title Fontsize "
                id={`${name}.optionsMeta.catAxisTitleFontSize`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={fontFaceOptions}
                name={`${name}.optionsMeta.catAxisTitleFontFace`}
                label="Title Fontface"
                id={`${name}.optionsMeta.catAxisTitleFontFace`}
                baseClassName="width-10 margin-l-3"
              />
          </LabelComponent>
          <LabelComponent label="Val Axis Options" baseClassName={"width-100"}>
              <HookFormCheckbox
                validation={{}}
                control={control}
                baseClassName="width-10 margin-l-3"
                errors={errors}
                label={"VAL Axis Title"}
                name={`${name}.optionsMeta.showValAxisTitle`}
                id={`${name}.optionsMeta.showValAxisTitle`}
              />
              <HookFormCheckbox
                validation={{}}
                control={control}
                baseClassName="width-10 margin-l-3"
                errors={errors}
                label={"VAL Axis"}
                name={`${name}.optionsMeta.valAxisHidden`}
                id={`${name}.optionsMeta.valAxisHidden`}
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={axisLineStyleOptions}
                name={`${name}.optionsMeta.valGridLine.style`}
                label={"VAL GridLine"}
                id={`${name}.optionsMeta.valGridLine.style`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={colorOptions}
                name={`${name}.optionsMeta.valAxisLabelColor`}
                label="Label Color "
                id={`${name}.optionsMeta.valAxisLabelColor`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.optionsMeta.valAxisLabelFontSize`}
                label="Label Fontsize "
                id={`${name}.optionsMeta.valAxisLabelFontSize`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={fontFaceOptions}
                name={`${name}.optionsMeta.valAxisLabelFontFace`}
                label="Label Fontface"
                id={`${name}.optionsMeta.valAxisLabelFontFace`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={colorOptions}
                name={`${name}.optionsMeta.valAxisTitleColor`}
                label="Title Color "
                id={`${name}.optionsMeta.valAxisTitleColor`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.optionsMeta.valAxisTitleFontSize`}
                label="Title Fontsize "
                id={`${name}.optionsMeta.valAxisTitleFontSize`}
                baseClassName="width-10 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={fontFaceOptions}
                name={`${name}.optionsMeta.valAxisTitleFontFace`}
                label="Title Fontface"
                id={`${name}.optionsMeta.valAxisTitleFontFace`}
                baseClassName="width-10 margin-l-3"
              />
          </LabelComponent>

          <div className="flex flex-row width-100 margin-t-5">
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.dataBorder.pt`}
              label="Data Border Width"
              id={`${name}.optionsMeta.dataBorder.pt`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.optionsMeta.dataBorder.color`}
              label="Data Border Color"
              id={`${name}.optionsMeta.dataBorder.color`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.optionsMeta.chartArea.fill.color`}
              label="ChartArea Fill Color"
              id={`${name}.optionsMeta.chartArea.fill.color`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.chartArea.fill.transparency`}
              label="ChartArea Transparency"
              id={`${name}.optionsMeta.chartArea.fill.transparency`}
              baseClassName="width-15 margin-l-3"
            />

            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.minValue`}
              label="Min"
              id={`${name}.optionsMeta.minValue`}
              baseClassName="width-10 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.maxValue`}
              label="Max"
              id={`${name}.optionsMeta.maxValue`}
              baseClassName="width-10 margin-l-3"
            />
          </div>
          <div className="flex flex-row width-100">
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.optionsMeta.dataLabelColor`}
              label="Data Label Color"
              id={`${name}.optionsMeta.dataLabelColor`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={fontFaceOptions}
              name={`${name}.optionsMeta.dataLabelFontFace`}
              label="Data Label Fontface"
              id={`${name}.optionsMeta.dataLabelFontFace`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              name={`${name}.optionsMeta.dataLabelFontSize`}
              label="Data Label Fontsize"
              id={`${name}.optionsMeta.dataLabelFontSize`}
              baseClassName="width-15 margin-l-3"
            />
          </div>
          <div className="flex flex-row width-100">
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.optionsMeta.plotArea.fill.color`}
              label="PlotArea Fill Color"
              id={`${name}.optionsMeta.plotArea.fill.color`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormInput
              control={control}
              errors={errors}
              validation={{}}
              label="PlotArea Border Width"
              name={`${name}.optionsMeta.plotArea.border.pt`}
              id={`${name}.optionsMeta.plotArea.border.pt`}
              baseClassName="width-15 margin-l-3"
            />
            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.optionsMeta.plotArea.border.color`}
              label="PlotArea Border Color"
              id={`${name}.optionsMeta.plotArea.border.color`}
              baseClassName="width-15 margin-l-3"
            />

            <HookFormSelect
              control={control}
              errors={errors}
              validation={{}}
              options={colorOptions}
              name={`${name}.optionsMeta.plotColor`}
              label={"Plot Color"}
              id={`${name}.optionsMeta.plotColor`}
              baseClassName="width-15 margin-l-3"
            />

            <HookFormInput
              validation={{}}
              control={control}
              errors={errors}
              baseClassName="width-15 margin-l-2"
              label="FontSize"
              id={`${name}.optionsMeta.fontSize`}
              name={`${name}.optionsMeta.fontSize`}
            />
            <HookFormSelect
              validation={{}}
              control={control}
              options={fontFaceOptions}
              baseClassName="width-15 margin-l-3"
              errors={errors}
              label="FontFace"
              id={`${name}.optionsMeta.fontFace`}
              name={`${name}.optionsMeta.fontFace`}
            />
            <HookFormSelect
              validation={{}}
              control={control}
              options={colorOptions}
              baseClassName="width-15 margin-l-3"
              errors={errors}
              label={"Color"}
              name={`${name}.optionsMeta.color`}
              id={`${name}.optionsMeta.color`}
            />
          </div>

          {/* <div className="flex flex-row width-100">
          {fields.map((field: any, index: number) => {
          return (
            <div key={field.id} className="flex flex-row flex-align-center">
             
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={colorOptions}
                name={`${name}.optionsMeta.chartColors[${index}]`}
                label={`Chart Color ${index + 1}`}
                id={`${name}.optionsMeta.chartColors[${index}]`}
                baseClassName=" width-40 margin-l-3"
              />
                 
              <div className="flex flex-align-center margin-t-4">
                <CustomButton
                  type="button"
                  transparent
                  primaryButton
                  iconProps={{
                    name: CUSTOM_SVG_ICON.Delete,
                    svgType: SVGType.CUSTOM,
                    size: "small",
                    baseclassname: "text-danger-color",
                  }}
                  noOutline
                  iconPosition={ICON_POSITION.LEFT}
                  handleClick={() => { remove(index); }}
                />
              </div>
            </div>
          );
        })}
          </div> */}
          <ChartColorForm control={control} errors={errors} name={name} label="Chart Color"/>
          <div>
            <ChartTickForm control={control} errors={errors} name={name} label="Tick Options" />
          </div>
        </div>
      </LabelComponent>
      {Boolean(title) && (
        <LabelComponent label="Title Options" baseClassName={"width-100"}>
          <HookFormSelect
            control={control}
            errors={errors}
            validation={{}}
            options={alignOptions}
            name={`${name}.optionsMeta.titleAlign`}
            label="Title Align"
            id={`${name}.optionsMeta.titleAlign`}
            baseClassName="width-15 margin-l-3"
          />
          <HookFormSelect
            control={control}
            errors={errors}
            validation={{}}
            options={colorOptions}
            name={`${name}.optionsMeta.titleColor`}
            label={"Title Color"}
            id={`${name}.optionsMeta.titleColor`}
            baseClassName="width-15 margin-l-3"
          />
          <HookFormInput
            control={control}
            errors={errors}
            validation={{}}
            label="Title Font Size"
            name={`${name}.optionsMeta.titleFontSize`}
            id={`${name}.optionsMeta.titleFontSize`}
            baseClassName="width-15 margin-l-3"
          />
          <HookFormSelect
            control={control}
            errors={errors}
            validation={{}}
            options={fontFaceOptions}
            label="Title Font Face"
            name={`${name}.optionsMeta.titleFontFace`}
            id={`${name}.optionsMeta.titleFontFace`}
            baseClassName="width-15 margin-l-3"
          />
          <HookFormCheckbox
            validation={{}}
            control={control}
            baseClassName="width-10 margin-l-2"
            errors={errors}
            label={"Bold"}
            name={`${name}.optionsMeta.titleBold`} // property
            id={`${name}.optionsMeta.titleBold`}
          />
        </LabelComponent>
      )}
      {Boolean(legends) && (
        <LabelComponent label="Legends Options" baseClassName={"width-100"}>
          <HookFormSelect
            control={control}
            errors={errors}
            validation={{}}
            options={legendPositionOptions}
            name={`${name}.optionsMeta.legendPos`}
            label="Legend Position"
            id={`${name}.optionsMeta.legendPos`}
            baseClassName="width-15 margin-l-3"
          />
          <HookFormSelect
            control={control}
            errors={errors}
            validation={{}}
            options={colorOptions}
            name={`${name}.optionsMeta.legendColor`}
            label={"Legend Color"}
            id={`${name}.optionsMeta.legendColor`}
            baseClassName="width-15 margin-l-3"
          />
          <HookFormInput
            control={control}
            errors={errors}
            validation={{}}
            label="Legend Font Size"
            name={`${name}.optionsMeta.legendFontSize`}
            id={`${name}.optionsMeta.legendFontSize`}
            baseClassName="width-15 margin-l-3"
          />
          <HookFormSelect
            control={control}
            errors={errors}
            validation={{}}
            options={fontFaceOptions}
            label="Legend Font Face"
            name={`${name}.optionsMeta.legendFontFace`}
            id={`${name}.optionsMeta.legendFontFace`}
            baseClassName="width-15 margin-l-3"
          />
        </LabelComponent>
      )}
    </TileComponent>
  );
}

export default MetaTypeChartForm;
