import { Fragment, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable, { IColumns } from "components/CustomTableComponent/CustomTable";
import { requestAnalyticsDownload } from "store/rext";
import { getAnalyticsDownloadState } from "store/selectors";
import FilterContainer, { DEFAULT_VALUE } from "./Filter";


const columns: IColumns[] = [
  {
    key: "_id",
    header: "Date",
  },
  {
    key: "count",
    header: "Created",
  },
  {
    key: "downloadedTotal",
    header: "Downloaded",
  },
];

function DowloandPPTContainer() {
  const dispatch = useDispatch();
  const { data: analyticsDownloadData, fetching: analyticsDownloadFetching } =
    useSelector(getAnalyticsDownloadState);

  const records = analyticsDownloadData?.records || [];

  useEffect(() => {
    dispatch(
      requestAnalyticsDownload({
        startDate: `${DEFAULT_VALUE.startDate}T00:00:00-08:00`,
        endDate: `${DEFAULT_VALUE.endDate}T23:59:59-08:00`,
      })
    );
  }, [dispatch]);

  const handleOnSubmit = (filterValue: any) => {
    dispatch(requestAnalyticsDownload(filterValue));
  };

  return (
    <Fragment>
      <FilterContainer handleFilterChange={handleOnSubmit}/>
      <CustomTable
        tableData={records}
        columns={columns}
        hasPagination={false}
        fetching={analyticsDownloadFetching || false}
        sortTable={false}
      />
    </Fragment>
  )
}

export default DowloandPPTContainer;
