import React from "react";
import { HookFormInput, HookFormSelect } from "components/FormInputs";
import CustomButton from "components/CustomButton";
import { useFieldArray } from "react-hook-form";
import LabelComponent from "components/LabelComponent";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import { borderTypeOptions, colorOptions } from "../utils";

interface IProps {
  control: any;
  errors: any;
  name: string;
  label?: string;
}

export default function GradientColorForm(props: IProps) {
  const { control, errors, name, label } = props;

  const { fields, append, remove } = useFieldArray({
    name: `${name}.optionsMeta.gradients`,
    control,
  });

  const handleAppendPoints = () => {
    append("");
  };

  const handleRemovePoints = (index: number) => {
    remove(index);
  };

  return (
    <div className="flex flex-row margin-l-2">
      {label && <div className="text-4 text-bold margin-r-6">{label}</div>}
      <CustomButton
        type="button"
        transparent
        primaryButton
        iconProps={{
          name: CUSTOM_SVG_ICON.PlusCircle,
          svgType: SVGType.CUSTOM,
          size: "small",
          baseclassname: "text-secondary-color",
        }}
        noOutline
        iconPosition={ICON_POSITION.LEFT}
        handleClick={handleAppendPoints}
      />
      <div className="flex flex-column">
        {fields.map((field: any, index: number) => {
          return (
            <div key={field.id} className="flex flex-row flex-align-center">
              <HookFormInput
                control={control}
                errors={errors}
                validation={{}}
                name={`${name}.optionsMeta.gradients[${index}].value`}
                label={`Value${index + 1}`}
                id={`${name}.optionsMeta.gradients[${index}].value`}
                baseClassName="width-35 margin-l-3"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                validation={{}}
                options={colorOptions}
                name={`${name}.optionsMeta.gradients[${index}].color`}
                label={`Color ${index + 1}`}
                id={`${name}.optionsMeta.gradients[${index}].color`}
                baseClassName=" width-40 margin-l-3"
              />
              <div className="flex flex-align-center margin-t-4">
                <CustomButton
                  type="button"
                  transparent
                  primaryButton
                  iconProps={{
                    name: CUSTOM_SVG_ICON.Delete,
                    svgType: SVGType.CUSTOM,
                    size: "small",
                    baseclassname: "text-danger-color",
                  }}
                  noOutline
                  iconPosition={ICON_POSITION.LEFT}
                  handleClick={() => handleRemovePoints(index)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
