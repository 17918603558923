export default () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      fill="currentColor"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 16"
    >
      <path
        d="M14,4.5V14c0,1.1-0.9,2-2,2v-1c0.6,0,1-0.4,1-1V4.5h-2c-0.8,0-1.5-0.7-1.5-1.5V1H4C3.4,1,3,1.4,3,2v9H2V2
	c0-1.1,0.9-2,2-2h5.5L14,4.5z M0,14.8c0,0.3,0.2,0.6,0.4,0.8c0.1,0.1,0.3,0.2,0.5,0.3C1.1,16,1.3,16,1.5,16c0.3,0,0.6-0.1,0.9-0.2
	c0.2-0.1,0.4-0.3,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.7c0-0.2,0-0.4-0.1-0.6c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.2l-0.6-0.1
	c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.5-0.2c0.1,0,0.3,0,0.4,0.1
	c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3h0.8c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.2-0.8-0.2
	c-0.3,0-0.6,0.1-0.8,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.4
	c0.2,0.1,0.3,0.2,0.5,0.2l0.6,0.1c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.2
	c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.4H0z M4.6,15.9h1l1.3-4H6l-0.9,3.1h0
	l-0.9-3.1H3.2L4.6,15.9L4.6,15.9z M10.2,12.5c-0.4-0.4-0.9-0.6-1.4-0.6c-1.1,0-2,0.9-2,2s0.9,2,2,2c0.9,0,1.7-0.6,1.9-1.5h-0.5
	c-0.2,0.6-0.8,1-1.4,1c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5c0.4,0,0.8,0.2,1.1,0.4L9,13.7h1.8v-1.8L10.2,12.5z"
      />
    </svg>
  );
};
