import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { HookFormInput } from "components/FormInputs";
import CustomButton from "components/CustomButton";

interface IFilterValues {
  startDate: string;
  endDate: string;
}

const endDate = new Date();
const startDate = new Date();
startDate.setDate(endDate.getDate() - 7);

const startFormattedDate = startDate.toISOString().split("T")[0];
const endFormattedDate = endDate.toISOString().split("T")[0];

export const DEFAULT_VALUE: IFilterValues = {
  startDate: startFormattedDate,
  endDate: endFormattedDate,
};

function FilterContainer({handleFilterChange}: any) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: useMemo(
      () => ({
        startDate: `${DEFAULT_VALUE.startDate}`,
        endDate: `${DEFAULT_VALUE.endDate}`
      }),
      []
    ),
  });

  const handleOnSubmit = (data: IFilterValues) => {
    const { startDate, endDate } = data;

    const transformedData = {
      startDate: `${startDate}T00:00:00-08:00`,
      endDate: `${endDate}T23:59:59-08:00`,
    };

    handleFilterChange(transformedData);
  };

 

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="flex flex-row flex-align-center flex-justify-between"
    >
      <HookFormInput
        control={control}
        errors={errors}
        type="date"
        name="startDate"
        id="startDate"
        label="Start Date"
        baseClassName="width-25 margin-l-3"
        defaultValue={DEFAULT_VALUE.startDate}
        onChange={(event: any) => setValue("startDate", event.target.value)}
      />
      <HookFormInput
        control={control}
        errors={errors}
        type="date"
        name="endDate"
        id="endDate"
        label="End Date"
        baseClassName="width-25 margin-l-3"
        defaultValue={DEFAULT_VALUE.endDate}
        onChange={(event: any) => setValue("endDate", event.target.value)}
      />
      <div className="padding-5">
        <CustomButton buttonText="Search" type="submit" primaryButton round />
      </div>
    </form>
  )
}

export default FilterContainer;
