import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { requestTemplatesList } from "store/rext/action";
import { getBaseUrl, getTemplateListData, getToken } from "store/selectors";
import Loading from "components/Loading";
import TableComponent from "components/TableComponent";
import { URLRoutes } from "URLRoutes";
import { IActionButtons } from "components/TableComponent/TableComponent";
import NavigationHeader from "components/NavigationHeader";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { toast } from "react-toastify";
import { fetchRequest, getFullUrl } from "Api";

let columns = [
  { key: "_id", header: "Id" },
  { key: "name", header: "TemplateName" },
  { key: "templateTypeId", header: "Template Type" },
];

function TemplateContainer() {
  const { templateTypeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const baseUrl = useSelector(getBaseUrl);
  const [fetching, setFetching] = useState<boolean>(false);
  const { data: templateListData, fetching: templateFetching } =
    useSelector(getTemplateListData);

  const handleClick = (templateTypeId: any) => {
    navigate(
      URLRoutes.clients.createTemplate.replace(
        ":templateTypeId",
        templateTypeId
      )
    );
  };

  const handleShowLayouts = (templateTypeId: string, templateId: string) => {
    navigate(`/pptFactory/template/${templateTypeId}/${templateId}`);
  };

  const handleEdit = (templateId: string) => {
    navigate(URLRoutes.clients.editTemplate.replace(":templateId", templateId));
  };

  const handleBackClick = () => {
    // navigate(-1)
    navigate(URLRoutes.clients.pptFactory);
  };

  const handleFullDownload = (id:any) => {
      dispatch(
        modalOperation.showModal(ModalsType.SlideDownloadModal, {
          onSave: async(data:any) => {
            dispatch(modalOperation.hideModal());
                if (!fetching) {
                  try {
                    setFetching(true);
                    const response = await fetchRequest(getFullUrl(baseUrl, URLRoutes.server.downloadTemplate, { urlParams:{templateId: id} }), token, "post", data);
                    // console.log(response , "res")
                    toast.success(response.message)
                    setFetching(false);
                  } catch (error: any) {
                    setFetching(false);
                    toast.error(error.message);
                  }
                }
          },
          onClose: () => {
            dispatch(modalOperation.hideModal());
          },
          title: "Template Download",
        })
      );
  }

  const actionButtons: IActionButtons[] = [
    {
      handleClick: (rowData: any) => handleEdit(rowData._id),
      icon: "Edit",
      iconColor: "primary",
      label: "Edit",
    },
    {
      handleClick: (rowData: any) =>
        handleShowLayouts(rowData.templateTypeId, rowData._id),
      icon: "Hamburger",
      iconColor: "secondary",
      label: "Show Templates",
    },
    {
      handleClick: (rowData:any) => handleFullDownload(rowData._id),
      icon: "Download",
      iconColor: "secondary",
      label: "Download",
    },
  ];

  useEffect(() => {
    dispatch(requestTemplatesList({ templateTypeId }));
  }, [dispatch]);

  return (
    <div className="flex flex-column width-100">
      <NavigationHeader
        title="Templates"
        buttonText="ADD TEMPLATE"
        handleBackClick={handleBackClick}
        handleSecondaryButtonClick={() => {
          handleClick(templateTypeId);
        }}
      />

      {templateFetching && <Loading />}
      {!templateFetching && (
        <TableComponent
          data={templateListData}
          columns={columns}
          actionButtons={actionButtons}
          tableClassName={"margin-6"}
        />
      )}
    </div>
  );
}

export default TemplateContainer;
